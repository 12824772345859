import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { withTranslation } from 'react-i18next'
import Fuse from 'fuse.js'
import Content from '@components/Content'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const PREFIX = 'HistoricalSurveyContent'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchBarTop: `${PREFIX}-searchBarTop`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	addUser: `${PREFIX}-addUser`,
	container: `${PREFIX}-container`,
	nested: `${PREFIX}-nested`,
	compactSearch: `${PREFIX}-compactSearch`,
}

const StyledContent = styled(Content)(({ theme }) => ({
	[`& .${classes.paper}`]: {
		margin: 'auto',
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.searchBarTop}`]: {
		marginBottom: '24px',
	},

	[`& .${classes.searchInput}`]: {
		fontSize: theme.typography.fontSize,
	},

	[`& .${classes.block}`]: {
		display: 'block',
	},

	[`& .${classes.addUser}`]: {
		marginRight: theme.spacing(1),
	},

	[`& .${classes.container}`]: {
		padding: '48px 36px',
	},

	[`& .${classes.nested}`]: {
		paddingLeft: theme.spacing(4),
		flexDirection: 'column',
	},

	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}))

class HistoricalContent extends Component {
	constructor(props) {
		super(props)
	}

	state = {
		searchVal: '',
		openSurveyQuestions: {},
	}

	static getDerivedStateFromProps(props, state) {
		return {
			...state,
			openSurveyQuestions: props.question.data.reduce((acc, question) => {
				if (!acc[question.issuer]) {
					acc[question.issuer] = {}
				}
				return acc
			}, state.openSurveyQuestions),
		}
	}

	componentDidMount = () => {
		this.hydrateData()
	}

	hydrateData = (issuer) => {
		const questions = this.props.question.data || []
		if (questions.length === 0 && !this.props.question.loading.read) {
			if (issuer) {
				this.props.getQuestions({ issuer })
			} else {
				this.props.getQuestions()
			}
		}
	}

	onRefresh = () => {
		this.props.getQuestions()
	}

	fuse(data) {
		const opts = {
			shouldSort: true,
			includeScore: true,
			minMatchCharLength: 4,
			keys: ['question', 'issuer', 'question.answer_txt'],
		}
		const fuse = new Fuse(data, opts)
		const res = this.state.searchVal
			? fuse.search(this.state.searchVal).reduce((acc, res) => {
					acc.push(res.item)
					return acc
			  }, [])
			: data
		return res
	}

	render() {
		const { question, isLoading } = this.props
		const questionData = this.fuse(question.data)
		const surveys = questionData.reduce((acc, question) => {
			if (!acc[question.issuer]) {
				acc[question.issuer] = [question]
			} else {
				acc[question.issuer].push(question)
			}
			return acc
		}, {})
		return Object.keys(surveys).length ? (
			<div className={classes.container}>
				<Paper className={classNames(classes.paper, classes.searchBarTop)}>
					<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
						<Toolbar>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<SearchIcon className={classes.block} color="inherit" />
								</Grid>
								<Grid item xs>
									<TextField
										value={this.state.searchVal}
										onChange={(event) => {
											this.setState({ searchVal: event.target.value })
										}}
										fullWidth
										placeholder="Search question"
										InputProps={{
											disableunderline: 'true',
											className: classes.searchInput,
											inputProps: {
												className: classes.compactSearch,
											},
										}}
									/>
								</Grid>

								<Grid item>
									<Tooltip arrow title="Reload" aria-label="reload">
										<IconButton color="inherit" onClick={this.onRefresh} size="large">
											<RefreshIcon className={classes.block} color="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
				</Paper>

				<Grid container spacing={3}>
					{Object.keys(surveys).map((surveyName, index) => {
						return (
							<Grid key={`${surveyName}-${index}`} item xs={12} sm={6}>
								<Paper className={classes.paper}>
									<AppBar
										className={classes.searchBar}
										position="static"
										color="default"
										elevation={0}
									>
										<Toolbar>
											<Grid container spacing={2} alignItems="center">
												<Grid item>
													<Typography color="textSecondary" align="center">
														{surveyName}
													</Typography>
												</Grid>
											</Grid>
										</Toolbar>
									</AppBar>
									<List>
										{surveys[surveyName].map((question, jndex) => {
											const open =
												this.state.openSurveyQuestions[question.issuer][question.question]
											return (
												<div key={`${question.issuer}-${question.question}-${jndex}`}>
													<ListItem
														role={undefined}
														onClick={(e) => {
															const questionGroup =
																this.state.openSurveyQuestions[question.issuer]

															if (questionGroup[question.question] !== undefined) {
																questionGroup[question.question] =
																	!questionGroup[question.question]
															} else {
																questionGroup[question.question] = true
															}

															this.setState({
																openSurveyQuestions: {
																	...this.state.openSurveyQuestions,
																	[question.issuer]: questionGroup,
																},
															})
														}}
													>
														<ListItemText id={question.id} primary={question.question} />
														{open ? <ExpandLess /> : <ExpandMore />}
													</ListItem>
													<Collapse in={open} timeout="auto" unmountOnExit>
														<List component="div" disablePadding>
															<ListItem
																dense
																className={classes.nested}
																alignItems="flex-start"
															>
																{Object.keys(question.answer).length === 0 &&
																question.answer.constructor === Object ? (
																	<ListItemText primary={`N/A`} />
																) : (
																	Object.keys(question.answer).map(
																		(answerGroup, i) => {
																			if (
																				typeof question.answer[answerGroup] ===
																				'string'
																			) {
																				return (
																					<ListItemText
																						key={`${question.issuer}-${question.answer[answerGroup]}-${i}`}
																						primary={
																							question.answer[answerGroup]
																						}
																					/>
																				)
																			}
																			return Object.keys(
																				question.answer[answerGroup]
																			).map((subAnswerGroup, j) => {
																				return (
																					<ListItemText
																						key={`${answerGroup}-${subAnswerGroup}-${j}`}
																						primary={`
                                      ${subAnswerGroup}: ${question.answer[answerGroup][subAnswerGroup]}`}
																					/>
																				)
																			})
																		}
																	)
																)}
															</ListItem>
														</List>
													</Collapse>
												</div>
											)
										})}
									</List>
								</Paper>
							</Grid>
						)
					})}
				</Grid>
			</div>
		) : (
			<StyledContent title={'No completed surveys'} subtitle={''} />
		)
	}
}

HistoricalContent.propTypes = {
	question: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	getQuestions: PropTypes.func.isRequired,
}

const mapStateToProps = ({ question }) => {
	return {
		question,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(HistoricalContent)
