import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SurveyContent from 'pages/SurveyContent'
import PrivateRoute from 'utils/PrivateRoute'
import { withTranslation } from 'react-i18next'

const PREFIX = 'survey'

const classes = {
	secondaryBar: `${PREFIX}-secondaryBar`,
}

const StyledPrivateRoute = styled(PrivateRoute)(({ theme }) => ({
	[`& .${classes.secondaryBar}`]: {
		zIndex: 0,
	},
}))

function Survey({ t, location, pageContext }) {
	const title = t(`${pageContext.titleKey}.title`)
	return (
		<>
			<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs>
							<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
								{title}
							</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<SurveyContent />
		</>
	)
}

Survey.propTypes = {
	location: PropTypes.object,
}

const SurveyPage = ({ location, ...rest }) => {
	return <StyledPrivateRoute location={location} component={Survey} {...rest} />
}

export default compose(withTranslation())(SurveyPage)
