import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import TabBar from 'molecules/TabBar'
import HistoricalContent from './HistoricalContent'
import CreateContent from './CreateContent'

import { withTranslation } from 'react-i18next'
import { LinearProgress } from '@components'

const PREFIX = 'SurveyContent'
const classes = {}

const Root = styled('div')(({ theme }) => ({}))

const tabNames = ['Historical', 'Create']
function SurveyContent(props) {
	const [selectedTab, setTab] = useState(tabNames[0]) // change with qs?
	const onTabChange = (newValue, tabName) => {
		setTab(tabName)
	}

	const isLoading = props.question.loading.read
	return (
		<Root>
			<TabBar tabNames={tabNames} tab={selectedTab} onTabChange={onTabChange} />
			<LinearProgress visible={isLoading} color="primary" />
			{
				{
					Historical: <HistoricalContent isLoading={isLoading} />,
					Create: <CreateContent isLoading={isLoading} />,
				}[selectedTab]
			}
		</Root>
	)
}

SurveyContent.propTypes = {
	question: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	getQuestions: PropTypes.func.isRequired,
}

const mapStateToProps = ({ question }) => {
	return {
		question,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(SurveyContent)
