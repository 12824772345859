import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'

import { withTranslation } from 'react-i18next'

import CommentIcon from '@mui/icons-material/Comment'

const PREFIX = 'CreateSurveyContent'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	addUser: `${PREFIX}-addUser`,
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.paper}`]: {
		margin: 'auto',
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.searchInput}`]: {
		fontSize: theme.typography.fontSize,
	},

	[`& .${classes.block}`]: {
		display: 'block',
	},

	[`& .${classes.addUser}`]: {
		marginRight: theme.spacing(1),
	},

	[`&.${classes.container}`]: {
		padding: '48px 36px',
	},
}))

class CreateContent extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { question, isLoading } = this.props
		return (
			<Root className={classes.container}>
				<Grid container spacing={3}>
					<Typography>Not Yet implemented</Typography>
				</Grid>
			</Root>
		)
	}
}

const mapStateToProps = ({ question }) => {
	return {
		question,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(CreateContent)
